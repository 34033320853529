import {Injectable} from '@angular/core';
import {FAC_MENU_ITEMS, OTHER_MENU_ITEMS} from '../definitions/definitions';
import {MenuItemDefinition} from '../portal/sidebar/menu-item';

interface UserNavigations {
    url: string;
    count: number;
    title?: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserTrackerService {
    trackedUrls = this._getLinks(FAC_MENU_ITEMS.concat(OTHER_MENU_ITEMS));

    private _getLinks(array: MenuItemDefinition[], prev: string[] = []) {
        return array.reduce((previous, current) => {
            if (current.children) {
                this._getLinks(current.children, prev);
            }
            previous.push(current.link);
            return previous;
        }, prev).filter(link => link !== undefined);
    }

    trackNavigation(url: string, userId: number, title: string) {
        title = title.split(' - Arsana')[0];
        if (title === '404' || title === '403' || url === '/' || !this.trackedUrls.find(possibleUrl => url.includes(possibleUrl))) return;
        let navigations: UserNavigations[] = JSON.parse(localStorage.getItem(`page-views-${userId}`)) as UserNavigations[];
        if (navigations) {
            const navigation = navigations.find(navUrl => navUrl.url === url);
            if (navigation) {
                navigation.count += 1;
            } else {
                navigations.push({url, count: 1, title});
            }
        } else {
            navigations = new Array<UserNavigations>({url, count: 1, title});
        }
        localStorage.setItem(`page-views-${userId}`, JSON.stringify(navigations));
    }

    getCommonlyVisitedPages(userId: number) {
        return (JSON.parse(localStorage.getItem(`page-views-${userId}`)) as UserNavigations[])?.sort((a, b) => a.count > b.count ? -1 : 1) || [];
    }
}
