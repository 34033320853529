import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewVersionAvailableModalComponent} from '../portal/@portal-theme/components/new-version-available-modal/new-version-available-modal.component';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    private refreshDelayed = new Subject<void>();
    private modalShown = false;

    get refreshDelayed$() {
        return this.refreshDelayed.asObservable();
    }

    constructor(private modalService: NgbModal) {
    }

    shouldUpdate(minVersionRequired: any): boolean {
        minVersionRequired = +minVersionRequired;
        if (isNaN(minVersionRequired)) {
            return false;
        }
        if (!(window as any).siteVersion) {
            return false;
        }
        const version = (window as any).siteVersion.split(':');

        if (version.length !== 2) {
            return false;
        }
        const revision = +version[1];
        if (isNaN(revision)) {
            return false;
        }

        return revision < minVersionRequired;
    }

    handleVersionCheck(res: HttpResponse<any> | HttpErrorResponse): Observable<null> {
        const ver = res.headers.get('Min-Version');
        if (this.modalShown || !ver || !this.shouldUpdate(ver)) {
            return;
        }

        this.modalShown = true;

        const modalPromise = this.modalService.open(
            NewVersionAvailableModalComponent,
            {
                backdrop: 'static',
                keyboard: false,
            }
        ).result;

        modalPromise.then(() => {
            // Refresh button was clicked or the timer ran out
            window.location.reload();
        }).catch(() => {
            // Close button was clicked
            this.modalShown = false;
            console.warn('Version update delayed!');
            this.refreshDelayed.next();
        });
    }
}
